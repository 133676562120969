import axios from 'axios';

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let auction_api_endpoint
if(providerEnv === "MainNet") {
  auction_api_endpoint = 'https://dry-bastion-74272.herokuapp.com'
} else if (providerEnv === "TestNet") {
  auction_api_endpoint = 'https://mysterious-oasis-02272.herokuapp.com'
} else {
  auction_api_endpoint = 'http://localhost:5001'
}
export default {

  get: () => [["auction",["JZZFATRCSFNWUURLN5ZUPYNUXXEEUXLAGBLI6B7HN4AHN4L7MRWWM5ZB4A","MPOY24JRCXDQ6FMZYNS76H5NCI4VVJKDZDSOQK2URRHVQUTVWT7OQDJBRM","7INV6MPMKCKBXSVMDLAKWRPCKZORTWUF2IM5VGIIIBKAFBJB2JCYKVM6RI","2AIGOAKXLPU2EQJ5YKAOVOHDNM3LO5UQXINIPODQASGA3FQMDEO7EWOAYE","KFLWO6H3B2KSDU2ITEVUBDH2RDHAGIY5IO7L2RP7UZ3PQEDRSFK75BIPQI","IDOM5ANJTWUG5Z2MRGIHXOR6DIIHYIJK2TVN4ELET575NURAC6AHRRQEQA","PBJLMGJ6455UYBIO5VGEQU337LCN77N7Q6INQBZXHH4MECVY4O3WV4BUUY"]],["reverse",["FDG36VBCBUVJOCTJM4NV5ELOGLZGMN4HG53FVDYGNL5EY4PQEIERTRQ3UE","HXVB3VHK4EHKVLUQBQ3UBY7F5SUPFQLW36Z5HCKK4S6ZEBMJUYP3OHPNAU","LB4ZYJEXYWUBVLBV66BBRFJIEVIQQUFEQAHGWJWZHOEPB2H75NIFSBFRJI"]],["token",["GY3QCUF7JSYM6PRKDZKJ2A4FETIZL27AZH4JG4J6VRNREHBNOKEM5LIBDM","NRXOZVP6Z7IIWU7L7NBWJFZB4CBE3HWO2FETTVK6XWPOGJYUSMTUGJP6WM","QWZ4CZ7CCL66DGR7CXHVJ3JPZHKUAP6VKKUKOPZXWMTQAUANIZ4OS3ZHS4"]],["offer",["Y5IDMW5FNLLTF2J6RZA2J5N6YQYT5ZXDXRVO7BJBMMH73QIYQEH7NOKMH4","ZZI4F6WE2QR7SCQC2RACEQTYMZZNYF7KDUU34Y4GQZZQVGKLV3WAYOV4BQ","FUOKDCC66IKJSQXXIEBZECHVDARIKOEMUT53NSLH6HYRE5WSG5M4YOARDE"]],["donate",["VV5VQGR7AEFXZ5O5E3DZRMRROP6XQRZW3ZB4N2V52QBRM5AOFLDATXGAEI","66CORPJLBW6RMVSSDXW357YEBQ6VSIH4GTL6SXJOA2RXEYAOHJDZ22NQVI","AKYTGZDSQ2Z6X5FZRHPA3B26JLJX7AP4NKPRSW4EYARCEKJJJZQWC7IXNU"]]]
  /*
  get: async (query = {}) => {
    let res = await axios.get(`${auction_api_endpoint}/api/v1/accounts`, {
      params: query,
      headers: {
	'Access-Control-Allow-Origin': true,
      },
    });
    return res.data || [];
  },
  */

}